import React, { useContext, useEffect, useState } from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Footer from "../components/footer";
import Blog from "../components/blog";
import fontBebasKaiWoff2 from "../fonts/BebasKai.woff2";
import fontSemplicitaLight from "../fonts/semplicita.light.otf";
import fontSemplicitaMedium from "../fonts/semplicita.medium.otf";
import SEO from "../components/seo";
import "../css/font-face.css";
import "../css/image-override.css";
import AutoSuggestEnhanced from "../components/auto-suggest-enhanced";
import Map from "../components/map";
import { UserContext } from "../context/UserContext";
import { getMenusTop, getMenusBottom } from "../components/menus";
import { slide as Menu } from "react-burger-menu";
import styles from "../components/burger-menu-styles";
import IntroVideo from "../video/intro-video.mp4";

const Step = ({ number, title, description }) => (
  <div
    className="flex-1 flex flex-col justify-center items-center content-center py-1 px-4 md:px-16"
    style={{ minWidth: "300px" }}
  >
    <span className="font-title text-purple-800 text-5xl">{number}</span>
    <span className="font-title text-purple-800 text-sm tracking-wide">
      {title}
    </span>
    <span
      className="font-sans font-light text-base text-gray-800 text-center m-2"
      dangerouslySetInnerHTML={{ __html: description }}
    />
  </div>
);

export default ({
  data: { datoCmsHomepage: h, allDatoCmsBlog: blogs, allDatoCmsCity: cities },
}) => {
  const [width, setWidth] = useState(
    typeof window === "undefined" ? null : window.innerWidth
  );
  const [city, setCity] = useState(null);
  const [service, setService] = useState(0);
  const userContext = useContext(UserContext);
  const menus = getMenusTop(userContext.user !== null);
  const services = [
    {
      label: "Hair",
      image: h.servicesimagehair,
      description: h.servicesdescriptionhair,
    },
    {
      label: "Makeup",
      image: h.servicesimagemakeup,
      description: h.servicesdescriptionmakeup,
    },
    {
      label: "Styling",
      image: h.servicesimagestyling,
      description: h.servicesdescriptionstyling,
    },
    {
      label: "Shopping",
      image: h.servicesimageshopping,
      description: h.servicesdescriptionshopping,
    },
  ];

  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const handleWindowSizeChange = () => setWidth(window.innerWidth);

  const ProcedureStep = ({ image, label, last = false }) => (
    <Link
      to="/services/"
      className={"flex-1 relative my-1" + (last ? "" : " mr-1")}
    >
      <Img className="flex-1" fluid={image.fluid} style={{ minWidth: 200 }} />
      <div
        className="absolute bottom-0 inset-x-0 bg-black text-white mx-16 py-4 text-center font-title tracking-wide"
        style={{ bottom: -20 }}
      >
        {label}
      </div>
    </Link>
  );

  return (
    <div>
      <SEO title="Home" seo={h.seoMetaTags}>
        <link
          rel="preload"
          as="font"
          href={fontBebasKaiWoff2}
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          as="font"
          href={fontSemplicitaLight}
          type="font/otf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          as="font"
          href={fontSemplicitaMedium}
          type="font/otf"
          crossOrigin="anonymous"
        />
      </SEO>
      <header className="relative flex flex-col">
        <div className="lg:hidden">
          <Menu
            styles={{
              ...styles,
              bmBurgerBars: {
                background: "white",
              },
            }}
          >
            {[...menus, ...getMenusBottom()].map((m) => (
              <Link
                className="outline-none text-white no-underline font-sans font-light my-1"
                style={{ display: "block" }}
                to={m.href + "/"}
                key={m.href}
              >
                {m.label}
              </Link>
            ))}
            {userContext.user && (
              <Link
                className="outline-none text-white no-underline font-sans font-light my-1"
                style={{ display: "block" }}
                to="/myaccount/mylookbook/"
              >
                {userContext.getFullName()}
              </Link>
            )}
            <Link
              to="/booking/"
              className="py-1 px-2 focus:outline-none bg-yellow-700 font-sans font-light text-white text-center tracking-wide text-lg"
            >
              Book now!
            </Link>
          </Menu>
        </div>
        {/* <Img fluid={h.topimage.fluid} className="flex-1 h-auto" /> */}
        <nav className="flex w-full justify-between py-10 px-8 bg-transparent bg-black">
          <Link to="/" className="cursor-pointer">
            <Img fixed={h.logo.fixed} />
          </Link>
          <ul className="list-reset lg:flex flex-wrap hidden md:items-center">
            {menus.map((m) => (
              <li className="mr-5" key={m.href}>
                <Link
                  className="text-white no-underline font-sans font-light"
                  to={m.href + "/"}
                >
                  {m.label}
                </Link>
              </li>
            ))}
            {userContext.user ? (
              <Link
                className="text-white no-underline font-sans font-light mr-2"
                to="/myaccount/mylookbook/"
              >
                {userContext.getFullName()}
              </Link>
            ) : null}
            <Link
              to="/booking/"
              className="py-1 px-2 focus:outline-none bg-yellow-700 font-sans font-light text-white text-center tracking-wide text-lg"
            >
              Book now!
            </Link>
          </ul>
        </nav>
        <video
          className="h-auto min-w-full z-0 block"
          autoPlay="autoplay"
          muted
          playsInline={true}
          src={IntroVideo}
        />
        <span className="text-white font-sans font-light absolute bottom-0 right-0 py-4 px-8">
          Travel in Style
        </span>
        <Link to="/booking/" className="block w-full bg-black p-8">
          <div className="w-full font-sans font-light text-white uppercase w-full text-center tracking-wide text-lg">
            Book your shopping trip now!
          </div>
        </Link>
      </header>
      <section>
        <div
          className="flex justify-center py-2 md:px-32"
          style={{ zIndex: "900" }}
        >
          <AutoSuggestEnhanced
            initialValue=""
            items={cities.edges.map((c) => c.node)}
            onSelected={setCity}
          />
          {city === null ? (
            <span className="bg-grey text-white font-title px-8 ml-1 flex justify-center items-center ">
              Explore
            </span>
          ) : (
            <Link
              className={
                "no-underline  hover:no-underline bg-black text-white font-title px-8 ml-1 flex justify-center items-center"
              }
              to={"/cities/" + ((city && city.slug) || "") + "/"}
            >
              Explore
            </Link>
          )}
        </div>
        {width && (
          <Map
            location={{
              longitude: 19.93587347919845,
              latitude: 15.610310020622634,
            }}
            zoom={width < 768 ? 0.1 : 2.4}
            height={width < 768 ? 200 : 500}
            points={cities.edges.map((c) => c.node)}
          />
        )}
      </section>
      <section className="flex flex-wrap justify-center">
        <ProcedureStep image={h.procedureonline} label="Online Consulation" />
        <ProcedureStep image={h.procedurevirtual} label="Virtual Styling" />
        <ProcedureStep image={h.procedureshopping} label="Personal Shopping" />
        <ProcedureStep
          image={h.procedurepackages}
          label="Shopping Packages"
          last={true}
        />
      </section>
      <section className="flex justify-center mt-16 mb-4">
        <div className="font-title text-purple-800 text-lg tracking-wide">
          {h.proceduretitle}
        </div>
      </section>
      <section className="flex flex-wrap pb-2 md:pb-16">
        <Step
          number="1"
          title={h.procedurestep1title}
          description={h.procedurestep1description}
        />
        <Step
          number="2"
          title={h.procedurestep2title}
          description={h.procedurestep2description}
        />
        <Step
          number="3"
          title={h.procedurestep3title}
          description={h.procedurestep3description}
        />
      </section>
      <section className="relative">
        <Img className="darken" fluid={h.servicesimage.fluid} />
        <div className="absolute flex inset-0 px-2 md:px-16 flex-wrap">
          <div className="flex flex-col flex-1 justify-start py-4">
            <span className="font-title text-white tracking-wide text-2xl">
              {h.servicestitle}
            </span>
            <span
              className="font-sans text-white font-light"
              dangerouslySetInnerHTML={{
                __html: services[service].description,
              }}
            />
          </div>
          <div className="flex flex-1 justify-center relative">
            <div className="flex-1 flex flex-col justify-center items-end px-4">
              {services.map((s, i) => (
                <button
                  key={s.label}
                  className={
                    "focus:outline-none font-sans text-white font-light uppercase" +
                    (i === service ? " text-2xl" : "")
                  }
                  onClick={() => setService(i)}
                >
                  {s.label}
                </button>
              ))}
            </div>
            <Img
              style={{ minWidth: 280, top: -30 }}
              className="flex-1 hidden md:block grey absolute"
              fluid={services[service].image.fluid}
            />
          </div>
        </div>
      </section>
      <section className="flex relative">
        <Img
          style={{ top: -30 }}
          className="hidden md:block flex-1 ml-8 absolute"
          fluid={h.stylistimage.fluid}
        />
        <div className="flex flex-col flex-1 justify-center items-center">
          <span className="text-purple-800 font-title text-2xl">
            {h.stylisttitle}
          </span>
          <span
            className="font-sans font-light text-gray-700 text-center my-4 mx-6"
            dangerouslySetInnerHTML={{ __html: h.stylistdescription }}
          />
          <Link
            className="no-underline bg-black text-white font-title px-8 py-1"
            to="/cities/"
          >
            Explore
          </Link>
        </div>
      </section>
      <section className="flex flex-col justify-center items-center w-full">
        <span className="text-purple-800 font-title text-2xl">
          {h.partnerstitle}
        </span>
        <div className="flex flex-wrap justify-center items-center">
          {h.partners.map((v) => (
            <Img className="mx-2" key={v.id} fixed={v.logo.fixed} />
          ))}
        </div>
      </section>
      <section className="flex flex-wrap justify-center">
        <div className="relative flex-1">
          <Img
            style={{ minWidth: 200 }}
            className=""
            fluid={h.lookoftheday.fluid}
          />
          <span className="mx-4 my-1 text-white font-title text-2xl uppercase absolute bottom-0 right-0">
            Look of the day
          </span>
        </div>
        <Link
          className="flex-1 bg-black flex flex-col justify-center items-center"
          to="/lookbook/"
        >
          <Img
            style={{ minWidth: 300 }}
            className=""
            fluid={h.lookbook.fluid}
          />
          <span className="mx-4 my-1 text-white font-title text-2xl uppercase">
            #LSLOOKBOOK
          </span>
        </Link>
      </section>
      <section className="flex flex-wrap px-4 py-8">
        <div
          className="flex flex-col justify-center items-center"
          style={{ flex: 2 }}
        >
          <Link className="text-purple-800 font-sans text-4xl" to="/blogs/">
            {h.blogtitle}
          </Link>
          <div className="flex flex-wrap">
            {blogs.edges
              .slice(0, 4)
              .map((v) => v.node)
              .map((v) => (
                <Blog blog={v} key={v.slug} />
              ))}
          </div>
        </div>
        <Img className="hidden md:block flex-1" fluid={h.blogimage.fluid} />
      </section>
      <Footer />
    </div>
  );
};

export const query = graphql`
  query HomePage {
    datoCmsHomepage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      topimage {
        fluid(maxWidth: 2000) {
          ...GatsbyDatoCmsFluid
        }
      }
      logo {
        fixed(
          width: 231
          height: 22
          imgixParams: { auto: "compress,format" }
        ) {
          ...GatsbyDatoCmsFixed
        }
        url
      }
      bookaction
      procedureonline {
        fluid(maxWidth: 300) {
          ...GatsbyDatoCmsFluid
        }
      }
      procedurevirtual {
        fluid(maxWidth: 300) {
          ...GatsbyDatoCmsFluid
        }
      }
      procedureshopping {
        fluid(maxWidth: 300) {
          ...GatsbyDatoCmsFluid
        }
      }
      procedurepackages {
        fluid(maxWidth: 300) {
          ...GatsbyDatoCmsFluid
        }
      }
      proceduretitle
      proceduredescription
      procedurestep1title
      procedurestep1description
      procedurestep2title
      procedurestep2description
      procedurestep3title
      procedurestep3description
      servicestitle
      servicesimage {
        fluid(
          maxWidth: 1000
          imgixParams: { fit: "crop", h: "500", w: "1000" }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      servicesdescriptionhair
      servicesdescriptionmakeup
      servicesdescriptionstyling
      servicesdescriptionshopping
      servicesimagehair {
        fluid(maxWidth: 280, imgixParams: { fit: "crop", h: "400", w: "280" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      servicesimagemakeup {
        fluid(maxWidth: 280, imgixParams: { fit: "crop", h: "400", w: "280" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      servicesimagestyling {
        fluid(maxWidth: 280, imgixParams: { fit: "crop", h: "400", w: "280" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      servicesimageshopping {
        fluid(maxWidth: 280, imgixParams: { fit: "crop", h: "400", w: "280" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      stylisttitle
      stylistdescription
      stylistimage {
        fluid(maxWidth: 600, imgixParams: { fit: "crop", w: "600", h: "600" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      partnerstitle
      lookoftheday {
        fluid(maxWidth: 600, imgixParams: { fit: "crop", h: "600", w: "600" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      lookbook {
        fluid(maxWidth: 600, imgixParams: { fit: "crop", h: "600", w: "600" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      blogtitle
      blogimage {
        fluid(
          maxWidth: 1000
          imgixParams: { fit: "crop", h: "560", w: "1000" }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      partners {
        id
        name
        logo {
          fixed(width: 80, imgixParams: { auto: "compress,format" }) {
            ...GatsbyDatoCmsFixed
          }
        }
      }
      introvideo {
        url
      }
    }
    allDatoCmsBlog(sort: { fields: [id], order: DESC }, limit: 4) {
      edges {
        node {
          id
          title
          slug
          category
          image {
            fluid(
              maxWidth: 300
              imgixParams: { fit: "crop", h: "200", w: "350" }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
    allDatoCmsCity(sort: { fields: [name] }) {
      edges {
        node {
          name
          slug
          location {
            longitude
            latitude
          }
        }
      }
    }
  }
`;
